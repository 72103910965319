import React, {useState} from "react";
import './burgermenu.css';
import { NavLink } from 'react-router-dom';
import fbimg from '../../Images/fbimg.png';
import instaimg from '../../Images/instaimg.png';


export function BurgerMenu() {

const [burger_class, setBurgerClass] = useState("burger-bar unclicked")
const [menu_class, setMenuClass] = useState("menu hidden")
const [isMenuClicked, setIsMenuClicked] = useState(false)

const updateMenu = () => {
    if(!isMenuClicked) {
        setBurgerClass("burger-bar clicked")
        setMenuClass("menu visible")
    }
    else {
        setBurgerClass("burger-bar unclicked")
        setMenuClass("menu hidden")
    }
    setIsMenuClicked(!isMenuClicked)
}

    return (
        <div className="burger-menu-whole">
            
                <div className="burger-menu" onClick={updateMenu}>
                    <div className={burger_class} ></div>
                    <div className={burger_class} ></div>
                    <div className={burger_class} ></div>
                </div>
            

                <div className={menu_class}>
                    <nav>
                        <ul>
                            <li><NavLink className="navbaritem" to="/homepage" activeClassName="active"  onClick={() => {window.scroll(0, 0);}}>Home<span></span></NavLink></li>
                            <li><NavLink className="navbaritem" to="/transformations" activeClassName="active"  onClick={() => {window.scroll(0, 0);}}>Transformations<span></span></NavLink></li>
                            <li><NavLink className="navbaritem" to="/about" activeClassName="active"  onClick={() => {window.scroll(0, 0);}}>About<span></span></NavLink></li>
                            <li><NavLink className="navbaritem" to="/contact" activeClassName="active"  onClick={() => {window.scroll(0, 0);}}>Contact<span></span></NavLink></li>
                        </ul>
                    </nav>
                </div>
        </div>
)};

