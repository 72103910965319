import React from 'react';
import './contactform.css';


export function ContactForm() {

    return (

<div className="maincontent">
        <div className="ContactForm">
            <form className="contact_form" action="./contact-form-handler.php" method="POST">
                <label for="name">Name</label>
                <input type="text" placeholder=" Enter your preferred name" name="name" required minlength="3" maxlength="30"></input>
                <label for="email">Email</label>
                <input type="text" placeholder=" Enter your email address" name="email" required minlength="6" maxlength="100"></input>
                <label for="mobile">Mobile</label>
                <input type="text" placeholder=" Enter your mobile number" name="mobile" required min="10" max="15"></input>
                <label for="address">Age</label>
                <input type="text" placeholder=" Enter your age" name="age" required minlength="1" maxlength="3"></input>
                <label for="message">Your Goals</label>
                <textarea type="text" placeholder=" Write a brief description about how we can help achieve your goals" name="message" rows="6" cols="48" required></textarea>
                <br></br>
                <button className="sendbutton">Send</button>
            </form>
        </div>
</div>

)};