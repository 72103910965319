import React from 'react';
import './footer.css'
import { NavLink } from 'react-router-dom';
import fbimg from '../../Images/fbimg.png';
import instaimg from '../../Images/instaimg.png';


export function Footer() {
return (
    <footer className="App-footer">

    <a href="https://www.facebook.com/JasonTraining" target="_blank"><img className="fbimgfooter" src={fbimg} alt="A Facebook Icon"/></a>
	<a href="https://www.instagram.com/jpt_coach/" target="_blank"><img className="instaimgfooter" src={instaimg} alt="An Instagram Icon"/></a>
    <p>
    © <a href="https://www.madeyouclick.co.uk" target="_blank" title="Open Facebook">Made You Click LTD</a> 2024 - All rights reserved.
    <br>
    </br>
    <NavLink to="/termsandconds"  onClick={() => {window.scroll(0, 0);}}>Terms & Conditions</NavLink> | <NavLink to="/privacypolicy"  onClick={() => {window.scroll(0, 0);}}>Privacy Policy</NavLink>
    <br></br>
    <a href="mailto:info@jasonpt.co.uk"> info@jasonpt.co.uk</a> | <a href="tel:07717899058"> 07717899058</a>
    </p>

    </footer>

)};