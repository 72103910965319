import './main.css';
import {Header} from './Components/Header/header';
import {Footer} from './Components/Footer/footer';
import { NavLink } from 'react-router-dom';
import jason from './Images/jason.jpg'
import { BurgerMenu } from './Components/Burgermenu/burgermenu';


export function AboutApp() {
  return (
    <div className="AboutApp">


<main>

<Header />
<BurgerMenu />

<body>

<div className="Homepagecontentcontainer">
    <div className="Homepagecontent4 animate glow">
    <div class="content">
    <h3>About</h3>
    <center><img className="jason" title="jason" src={jason} alt="A pic of Jason"/></center>
    <br></br>
    <p>My name's Jason and I've been in the industry for <strong>10 years</strong>. I have worked with a real mixture of different people across all age groups, with varying ranges of experience and individual 
    goals to be achieved. I take pride in creating a training environment that is fun and enjoyable yet pushes my clients harder each session to achieve the goals they want. 
    <br></br>
    <br></br>
    Recently I have expanded the business by developing an <strong>App</strong> for my clients to use along side their training. This is to help track your workouts, nutrition and body composition progress to keep all clients up-to-date and accountable.</p>
    <br></br>
    </div>
    </div>
    <div className="Homepagecontent3 animate glow delay4">
    <div class="content">
    <h3>The App</h3>
    <p>You will be assigned a training plan once you have first logged into the App. This is specific to your goals created by your assigned coach. 
    You will be able to track your weights and reps for your sessions and your coach will be able to see how you do.
    The App will also keep track of your nutrition, your macro breakdowns and your eating and sleeping habits throughout the week.
    This is to keep you accountable and to track your progress. 
    </p>
    <br></br>
    <br></br>
	<h3>Sessions & Payment</h3>
    <p>Payment for sessions will have to be made before your session starts. 
    Cancellation policy is <strong>24 hours</strong> before your session begins otherwise <strong>you will still be charged</strong> for a full session (dependant on the circumstances).
    </p>
    <br></br>
    <br></br>
    <br></br>
    <div className="animate glow delay8"><center><NavLink to="/contact"  onClick={() => {window.scroll(0, 0);}}><button className="sendbutton">Enquire Now</button></NavLink></center></div>
    </div>
    </div>
</div>

</body>

<Footer />

</main>


</div>

)};

