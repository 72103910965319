import './main.css';
import {Header} from './Components/Header/header';
import {Footer} from './Components/Footer/footer';
import { ContactForm } from './Components/Contact Form/contactform';
import oakley from './Images/oakley.webp';
import { BurgerMenu } from './Components/Burgermenu/burgermenu';

export function ContactApp() {
  return (
    <div className="ContactApp">


<main>

<Header />
<BurgerMenu />
<body>

<div className="Homepagecontentcontainer">
    <div className="Homepagecontent4 animate glow">
    <div class="content">
    <h3>Contact</h3>
    <h4><strong>&#x2709;</strong><a href="mailto:info@jasonpt.co.uk"> info@jasonpt.co.uk</a></h4>
	<h4><strong>&#x260F;</strong><a href="tel:07717899058"> 07717899058</a></h4>
    <ContactForm />
    </div>
    </div>
    <div className="Homepagecontent3 animate glow delay4">
    <div class="content">
	<h3>Our Location</h3>
    <br></br>
    <br></br>
	<center><img className="oakley" title="Oakley Fitness" src={oakley} alt="A pic of Oakley Fitness"/></center>
    <br></br>
    <p>JPT operates inside Oakley Fitness which is within the stunning Vale Royal Methodist Church in the centre of Royal Tunbridge Wells. Make the gym your religion - join me today.</p>
	<h4><strong>&#x2616;</strong> Oakley Fitness, Vale Royal<br></br>Methodist Church, Vale Rd,<br></br>Tunbridge Wells TN1 1BF</h4>
    <br></br>
    <center><a href="https://www.google.com/maps/place/Oakley+Fitness/@51.1287553,0.2589569,17z/data=!3m1!4b1!4m6!3m5!1s0x47df451d7ca79599:0xebefb5af044af177!8m2!3d51.128752!4d0.2615318!16s%2Fg%2F11hdy6wr92?entry=ttu" target="_blank"><button className="sendbutton">Google Maps</button></a></center>
    </div>
    </div>
</div>

</body>

<Footer />

</main>


</div>

)};

