import './main.css';
import { NavLink } from 'react-router-dom';
import {Header} from './Components/Header/header';
import {Footer} from './Components/Footer/footer';
import { BurgerMenu } from './Components/Burgermenu/burgermenu';


export function HomepageApp() {
  return (
    <div className="HomepageApp">


<main>

<Header />
<BurgerMenu />
<body>

<div className="Homepagecontentcontainer">
    <div className="Homepagecontent1 animate glow">
      <h1 className="slide-right">Welcome<br></br> to JPT Body Transformation Coach.</h1>
    </div>
    <div className="Homepagecontent2 animate glow delay7">
      <h2>
        "With over Ten years of industry experience, I aspire to transform as many lives as I can.
        <br>
        </br>
        <br>
        </br>
        I provide personal training face to face in the gym and online coaching through my App that is specifically designed to help support you.
        <br>
        </br>
        <br>
        </br>
        Which means I will be with you 24/7 to help you achieve your goals."
      </h2>
    </div>
</div>
<br></br>
<br></br>
<br></br>
<div className="animate glow delay9"><center><NavLink to="/contact"  onClick={() => {window.scroll(0, 0);}}><button className="sendbutton">Enquire Now</button></NavLink></center></div>


</body>

<Footer />

</main>


</div>

)};

