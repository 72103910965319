import './main.css';
import {Header} from './Components/Header/header';
import {Footer} from './Components/Footer/footer';
import { NavLink } from 'react-router-dom';
import { BurgerMenu } from './Components/Burgermenu/burgermenu';
import tpic1 from "./Images/tpic1.jpg"
import tpic2 from "./Images/tpic2.jpg"
import tpic3 from "./Images/tpic3.jpg"
import tpic4 from "./Images/tpic4.jpg"

export function TransformationsApp() {
  return (
    <div className="TransformationsApp">

<main>

<Header />
<BurgerMenu />
<body>
<br></br>
<div className="animate glow delay3"><h3>Transformations</h3></div>
<br></br>
<p className="para1 animate glow delay3">Please find some before and after pictures of my clients' transformations below.
</p>
<br></br>
<br></br>
<br></br>
<div className="animate glow delay6"><center><NavLink to="/contact"  onClick={() => {window.scroll(0, 0);}}><button className="sendbutton">Enquire Now</button></NavLink></center></div>
<section>

<div className="transformationcontainer">
<div className="containerpic animate glow delay9">
  <img className="transformpic" title="Transformation 1" src={tpic1} alt="1st Transformation Picture"/>
  <div className="overlay">
    <div className="pictext">8 Week Transformation - “I have dropped my body fat by 18%, lost 3 inches on my legs, my glutes and hips and over a stone in weight in total”</div>
  </div>
</div>
<div className="containerpic animate glow delay10">
  <img className="transformpic" title="Transformation 1" src={tpic2} alt="1st Transformation Picture"/>
  <div className="overlay">
    <div className="pictext">12 Week Transformation - “In just 3 months I have gained 9kg in lean muscle, my chest has grown by 5 inches and my arms have grown by 2 inches.”</div>
  </div>
</div>
<div className="containerpic animate glow delay11">
  <img className="transformpic" title="Transformation 1" src={tpic3} alt="1st Transformation Picture"/>
  <div className="overlay">
    <div className="pictext">2 Year Transformation - “I have gained over 28kg in weight, putting on size in my chest, legs and arms and developed a better understanding how important nutrition is”</div>
  </div>
</div>
<div className="containerpic animate glow delay12">
  <img className="transformpic" title="Transformation 1" src={tpic4} alt="1st Transformation Picture"/>
  <div className="overlay">
    <div className="pictext">My Own Personal Transformation - “Coming out of lockdown I wasn't in a good place physically so I put in the work and 8 weeks later and 10kg lost I was back to feeling healthy and happy with my physique”</div>
  </div>
</div>
</div>





</section>
</body>

<Footer />

</main>


</div>

  )};

