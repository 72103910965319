import './App.css';
import { RouterProvider, createBrowserRouter, createRoutesFromElements, Route} from 'react-router-dom';
import { HomepageApp } from './homepage';
import { TransformationsApp } from './transformations';
import { ContactApp} from './contact';
import { AboutApp } from './about';
import { TermsAndCondsApp } from './termsandconds';
import { PrivacyPolicyApp } from './privacypolicy';


const router = createBrowserRouter( createRoutesFromElements(

  <>
      <Route path="/" element={ <HomepageApp/>}>
      </Route>
      <Route path='homepage' element={ <HomepageApp/> } />
      <Route path='transformations' element={ <TransformationsApp/> } />
      <Route path='about' element={ <AboutApp/> } />
      <Route path='contact' element={ <ContactApp/> } />
      <Route path='termsandconds' element={ <TermsAndCondsApp/> } />
      <Route path='privacypolicy' element={ <PrivacyPolicyApp/> } />
  </>
  
  ));

export function App() {
  return (

    <RouterProvider router={ router } />
  );
}

