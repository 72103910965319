import React from 'react';
import './header.css'
import { NavLink } from 'react-router-dom';
import logoimg from '../../Images/logoimg.png';
import fbimg from '../../Images/fbimg.png';
import instaimg from '../../Images/instaimg.png';

export function Header() {
return (
    <header className="App-header">
    <NavLink className="navbaritem" to="/homepage" activeClassName="active"  onClick={() => {window.scroll(0, 0);}}><img className="logoimg" title="logoimg" src={logoimg} alt="JPT Logo"/></NavLink>
    <a href="https://www.facebook.com/JasonTraining" target="_blank"><img className="fbimg" src={fbimg} alt="A Facebook Icon"/></a>
	<a href="https://www.instagram.com/jpt_coach/" target="_blank"><img className="instaimg" src={instaimg} alt="An Instagram Icon"/></a>
<nav className='navbarnormal'>
	<ul>
		<li><NavLink className="navbaritem" to="/homepage" activeClassName="active"  onClick={() => {window.scroll(0, 0);}}>Home<span></span></NavLink></li>
        <li><NavLink className="navbaritem" to="/transformations" activeClassName="active"  onClick={() => {window.scroll(0, 0);}}>Transformations<span></span></NavLink></li>
		<li><NavLink className="navbaritem" to="/about" activeClassName="active"  onClick={() => {window.scroll(0, 0);}}>About<span></span></NavLink></li>
        <li><NavLink className="navbaritem" to="/contact" activeClassName="active"  onClick={() => {window.scroll(0, 0);}}>Contact<span></span></NavLink></li>
	</ul>
</nav>
</header>





)};
